/* stylelint-disable selector-not-notation */

.FrontpageDatePicker :global {
    .date-picker__modal {
        max-width: 800px;
    }

    &--single-month {
        max-width: 400px;
    }

    .date-picker__day {
        aspect-ratio: 1 / 1.2;

        &:not(:disabled):not(
                .date-picker__day--expired,
                .date-picker__day--start,
                .date-picker__day--between,
                .date-picker__day--non-current,
                .date-picker__day--end
            ) {
            background-color: var(--blue-primary-subtle);

            &:hover {
                border-color: #0063fb;
            }
        }
    }
}
