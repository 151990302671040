.horizontalScrollingItems {
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    gap: 16px;
    padding: 6px 0;
}

.horizontalScrollingItems::-webkit-scrollbar {
    display: none;
}

.horizontalScrollingItem {
    display: block;
    width: 230px;
    min-width: 230px;
}
