.explanationBox {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    margin-top: 0.75rem;
    width: 100%;
}

.summaryTitle {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    color: #0063fb;
    font-weight: 600;
    font-size: 12px;
    padding: 0 4px;
    gap: 4px;
    border-radius: 2px;
    width: fit-content;
}

.summary {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    color: #464646;
    cursor: pointer;

    &::-webkit-details-marker {
        display: none;
    }

    &:focus {
        outline: none;
    }

    &:hover .summaryTitle,
    &:focus .summaryTitle {
        text-decoration: underline;
    }

    &:focus-visible .summaryTitle {
        outline: 2px solid #0063fb;
        outline-offset: -2px;
    }
}

.chevronDownIcon {
    transition: transform 0.2s ease;
    height: 16px;
    margin: 0;
    padding: 0;
}

.rotate {
    transform: rotate(180deg);
}

.priceLevelDisplay {
    border: 1px solid #cacad1 !important;
    max-width: 4rem;
    padding: 0.1rem 0.5rem;
}

.explanationContent {
    max-width: 600px;
    padding: 1.5rem 0 0;
}

.descriptiveList {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.75rem 0.5rem;
    align-items: center;
    margin: 1rem 0;

    dt,
    dd {
        margin: 0;
    }
}
