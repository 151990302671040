.openJaw {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
}

.openJawAutocomplete {
    display: flex;
    gap: 1rem;
    flex-flow: column nowrap;
    width: 100%;
    flex: 2;
    min-width: 250px;
}

.openJawDatePicker :global {
    display: flex;
    gap: 1rem;
    flex-flow: column nowrap;
    width: 100%;
    flex: 1;

    .date-picker__modal {
        margin-top: 16rem;
    }

    .date-picker__fieldset {
        flex-flow: column nowrap;
        min-width: 150px;
    }
}

.openJawPassengersAndCabin {
    display: flex;
    gap: 1rem;
    flex-flow: column nowrap;
    width: 100%;
    flex: 1;
}
