.priceLevelDisplay {
    display: flex;
    place-content: center;
    align-items: center;
    background-color: #fff;
    color: #464646;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 2px;
    height: fit-content;
    line-height: 1.33em;
    width: 100%;
    min-width: fit-content;
    white-space: nowrap;

    @media screen and (width <= 340px) {
        font-size: 1rem;
    }
}

.priceLevelDisplayDefault {
    background-color: transparent;
}
