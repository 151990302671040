/* stylelint-disable selector-not-notation */

.date-picker__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff1a;
    backdrop-filter: blur(3px);
    z-index: 9;
}

.date-picker :global {
    --blue-primary: #0063fb;
    --blue-primary-subtle: #eff5ff;
    --blue-secondary: #06befb;
    --toothpaste: #b6f0ff;
    --white: #fff;
    --watermelon: #ff3b30;
    --licorice: #464646;
    --border-radius: 2px;
    --small-screen-modal-header-height: 6rem;

    display: flex;
    flex-flow: column nowrap;

    .date-picker__fieldset {
        display: flex;
        flex-flow: row nowrap;
        gap: 1rem;
        width: fit-content;
        border-color: transparent;

        &--single-date {
            flex-flow: column nowrap;
            width: 100%;
        }
    }

    .date-picker__input {
        &:read-only {
            padding: 12px 8px;
            line-height: inherit;
            outline: none;
            border-color: var(--f-bluegray-300);
        }
    }

    .date-picker__modal {
        display: flex;
        position: absolute;
        margin-top: 7.6rem;
        left: 50%;
        transform: translate(-50%, 0);
        flex-flow: column nowrap;
        align-items: stretch;
        align-self: auto;
        place-content: flex-start;
        height: fit-content;
        max-height: 600px;
        width: calc(100% - 2rem);
        max-width: 650px;
        border-radius: 16px;
        box-shadow: 0 1px 3px 0 #3c40434d, 0 4px 8px 3px #3c404326;
        background-color: var(--white);
        padding: 1rem 1.75rem 1.75rem;
        gap: 1rem;
        z-index: 9;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;

        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }

        &--single-month {
            max-width: 400px;
        }

        &--open:not(.date-picker__modal--small-screen) {
            animation: scale-in 0.2s ease-out forwards;
        }

        &--closed {
            display: none;
        }

        &--small-screen {
            position: fixed;
            inset: auto 0 0;
            margin: 0;
            align-items: flex-end;
            height: auto;
            max-height: calc(100% - 12rem - env(safe-area-inset-bottom));
            width: 100%;
            border-radius: 16px 16px 0 0;
            box-shadow: 2px 2px 12px #6f899fb3;
            padding: 0.5rem;
            transform: none;
        }
    }

    .date-picker__modal-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 16px 16px 0 0;
        height: 3.5rem;
        padding: 2rem 0;
        background-color: var(--white);
        border-bottom: 1px solid transparent;

        &--small-screen {
            position: fixed;
            left: 0;
            margin: -1rem 0 0;
            padding: 1.25rem 1rem 0;
            height: var(--small-screen-modal-header-height);
            z-index: 9;
        }
    }

    .date-picker__nav-btn {
        display: flex;
        place-content: center;
        align-items: center;
        border: 1px solid transparent;
        border-radius: var(--border-radius);
        height: 3rem;
        width: 3rem;
        background-color: var(--white);
        cursor: pointer;

        &:disabled {
            opacity: 0.25;
            cursor: default;
        }

        &:hover:not(:disabled) {
            background-color: var(--blue-primary-subtle);
        }

        &:focus-visible:not(:disabled) {
            outline: 2px solid var(--blue-primary);
            outline-offset: -2px;
        }

        &:active:not(:disabled) {
            transform: scale(0.9);
        }
    }

    .date-picker__modal-footer {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1rem;
        gap: 1rem;

        &__optional {
            width: 100%;
        }

        &--small-screen {
            margin-top: 0;
            flex-flow: column nowrap;
        }
    }

    .date-picker__month-title {
        display: flex;
        align-self: center;
        text-transform: lowercase;
        width: fit-content;
        font-weight: 600;
        margin: -4.15rem 0 1.5rem;

        &--small-screen {
            position: fixed;
            z-index: 9;
            margin: -4.5rem 0 0;
            padding: 0;
        }

        &--outside-range {
            opacity: 0.33;
        }
    }

    .date-picker__visual-focus {
        outline: 2px solid var(--blue-primary);
        outline-offset: -2px;
    }

    .date-picker__month-panel-group {
        display: flex;
        flex-flow: row wrap;
        gap: 6rem 1.5rem;

        &--small-screen {
            position: relative;
            height: fit-content;
            bottom: 0;
            left: 0;
            border-color: transparent;
            box-shadow: none;
            margin: var(--small-screen-modal-header-height) 0 0;
            width: 100%;
            border-radius: 0;
        }
    }

    .date-picker__month-panel {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        flex: 1;
    }

    .date-picker__calendar {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        align-self: auto;
        place-content: flex-start;
        gap: 2px;
        width: 100%;
    }

    .date-picker__weekday-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        line-height: 1rem;

        &--outside-range {
            opacity: 0.33;
        }
    }

    .date-picker__weekday-header-day {
        display: flex;
        justify-content: center;
        width: calc(100% * (1 / 7));
    }

    .date-picker__weekday-header-day abbr {
        font-size: 1.2rem;
        font-weight: 500;
        color: #5c5c66;
        text-decoration: none;
        text-transform: lowercase;
    }

    .date-picker__calendar__table-row {
        display: flex;
        flex-flow: row nowrap;
        gap: 2px;
    }

    .date-picker__calendar__table-cell {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-self: center;
        place-content: center;
        width: calc(100% * (1 / 7) - 2px);
        border-radius: var(--border-radius);

        &:nth-child(7n) {
            width: calc(100% * (1 / 7) - 1px);
        }
    }

    .date-picker__day {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 3.5rem;
        border: solid 1px transparent;
        border-radius: var(--border-radius);
        cursor: pointer;
        background-color: transparent;
        aspect-ratio: 1 / 1;
        color: var(--licorice);

        &--non-current,
        &--non-current.date-picker__day--between,
        &--non-current.date-picker__day--start,
        &--non-current.date-picker__day--end {
            opacity: 0.15;
            cursor: default;
            pointer-events: none;
            user-select: none;
        }

        &--non-current-hidden {
            display: none;
        }

        &__date-time {
            width: 2ch;
            font-size: 1.5rem;
            font-weight: 600;
        }

        &--expired {
            opacity: 0.33;
            cursor: default;
            text-decoration: line-through;
            background-color: transparent;

            .date-picker__price-display {
                color: transparent;
                background-color: transparent;
            }
        }

        &--outside-range {
            opacity: 0.33;
            cursor: default;
            background-color: transparent;

            .date-picker__price-display {
                color: transparent;
                background-color: transparent;
            }
        }

        &--start,
        &--end {
            background-color: var(--blue-primary);
            color: var(--white);
            opacity: 1;
        }

        &--between {
            background-color: #c2dafe;
            opacity: 1;
        }

        &:focus-visible:not(:is(:disabled, .date-picker__day--expired)) {
            outline: 2px solid var(--blue-primary);
            outline-offset: -2px;
        }
    }
}
