.CarSearch {
    display: flex;
    background-color: #f1f9ff;
    border-radius: 8px;
    padding: 2rem;
    flex-flow: row wrap;
    gap: 1rem;
}

.fieldsetLocation {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    flex: 1;

    @media screen and (width <= 640px) {
        flex-flow: column nowrap;
    }
}

.Group {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 1.5rem;
}

.DateAndTimeGroup {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: 1.5rem;
    flex: 2;
}

.fieldsetTime {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    gap: 1rem;
    min-width: 11ch;
}

.fieldsetDate :global {
    flex: 1;

    .date-picker__fieldset {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        min-width: 11ch;
    }

    .date-picker__modal {
        margin-top: 16rem;
    }
}

.fieldsetCheckbox {
    display: flex;
    flex-flow: column nowrap;
    min-width: fit-content;
    gap: 0.2rem;
    flex: 1;
    margin: 2rem 1rem 0 0;
}

.errorPadding {
    padding: 0 0 2rem;
}
