@media (width <= 600px) {
    .travelMenuTabs {
        font-size: 14px;
    }

    .navItemText {
        width: fit-content;
        padding: 16px 0 0;
        flex-grow: 1;
    }

    .travelMenuTabs [role="tablist"] {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
}

.carSale {
    position: absolute;
    margin: -40px 0 0 23px;
    background-color: #ff5844;
    border-radius: 50%;
    height: 28px;
    width: 28px;
}

.carSale::before {
    content: "Salg!";
    position: relative;
    font-size: 9px;
    color: #fff;
}
