.imgWrapper {
    border-radius: 16px 16px 0 0;

    @media (width >= 768px) {
        border-radius: 0 16px 16px 0;
    }
}

.img {
    min-height: 28.6rem;
    background-position: 50% 50%;

    /* style for alt text */
    background-color: var(--f-bluegray-100);
}

.img::before {
    /* style for alt text */
    content: "";
    padding: 5rem;
}
